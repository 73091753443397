<template>
  <v-main class="login">
    <TopbarAuth />

    <v-container fluid class="fill-height">
      <v-row column>
        <!-- Card -->
        <v-col cols="12" md="6" offset-md="3">
          <Recaptcha ref="recaptcha" @verify="(response) => login(response)" />

          <v-row align="center">
            <!-- Title -->
            <v-col cols="12" class="text-center bg-white">
              <h1 class="MontserratBold">{{ $t("auth.login.title") }}</h1>
            </v-col>

            <!-- Form -->
            <v-col cols="12" class="mt-6">
              <v-form>
                <v-row no-gutters>
                  <!-- Email -->
                  <v-col cols="12" md="10" class="offset-md-1 py-0">
                    <v-text-field
                      shaped
                      outlined
                      prepend-icon="fas fa-user"
                      name="login"
                      :label="$t('auth.login.labels.email')"
                      type="text"
                      v-model="form.email"
                    ></v-text-field>
                  </v-col>

                  <!-- Password -->
                  <v-col cols="12" md="10" class="offset-md-1 py-0">
                    <v-text-field
                      shaped
                      outlined
                      id="password"
                      prepend-icon="fas fa-lock"
                      name="password"
                      :label="$t('auth.login.labels.password')"
                      type="password"
                      @keyup.enter.native="login"
                      v-model="form.password"
                    ></v-text-field>
                  </v-col>

                  <!--v-col cols="12" md="10" class="offset-md-1 py-0 text-right">
                    <v-btn text class="py-0" @click="showAskResetPasswordDialog = true">
                      {{$t('auth.login.buttons.forgotPassword')}}
                    </v-btn>
                  </v-col-->

                  <v-col cols="12" md="10" class="offset-md-1">
                    <AlertBar />
                  </v-col>

                  <!-- Button -->
                  <v-col cols="12" md="10" class="offset-md-1 text-center mt-6">
                    <v-btn
                      style="width: 200px; text-transform: none !important"
                      color="primary"
                      class="btn-primary"
                      :loading="isLoading"
                      @click="executeRecaptcha"
                    >
                      {{ $t("auth.login.buttons.login") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!--AskResetPassword
      v-if="showAskResetPasswordDialog"
      :value="showAskResetPasswordDialog"
      @close="showAskResetPasswordDialog = false" /-->
  </v-main>
</template>

<script>
// import validator from "validator";
import cloneDeep from "clone-deep";

import formRules from "../../mixins/formRules";

export default {
  name: "Login",

  components: {
    TopbarAuth: () => import("@/components/Common/Bars/TopbarAuth"),
    AlertBar: () => import("@/components/Common/Bars/AlertBar"),
    Recaptcha: () => import("@/components/Common/Recaptcha"),
    //SupportDialog: () => import("@/components/Common/SupportDialog"),
    //AskResetPassword: () => import("@/components/Auth/AskResetPasswordDialog")
  },

  mixins: [formRules],

  data() {
    return {
      form: {},

      default_form: {
        email: "",
        password: "",
      },

      isLoading: false,

      //showSupportDialog: false,
      //showAskResetPasswordDialog: false,

      pageTitle: this.$t("auth.login.pageTitle"),
    };
  },

  created() {
    this.$session.destroy();
    this.$store.commit("pageHead/setTitle", this.$t("auth.login.pageTitle"));
    this.form = cloneDeep(this.default_form);
  },

  methods: {
    login(recaptcha) {
      this.$store.commit("alert/hideAlert");

      if (this.form.email === "" || this.form.password === "")
        return this.$store.commit(
          "alert/showError",
          this.$t("common.errors.ALL_REQUIRED")
        );

      this.isLoading = true;
      this.form["g-recaptcha-response"] = recaptcha;

      this.$http
        .post(
          "/admin/auth/login?api_key=" + process.env.VUE_APP_API_KEY,
          this.form
        )
        .then((res) => {
          // OK
          if (res.status === 200) {
            this.$session.start();

            this.$session.set("jwt", res.data.token);

            this.$session.set("email", res.data.user.email);
            this.$session.set("person", res.data.account.person);
            this.$session.set("id", res.data.user.id);

            return this.$router.push({ name: "Accounts" });
          }
        })
        .catch((err) => {
          this.form.password = "";

          if (err.response && err.response.status === 400) {
            if (err.response.type === "TOO_MANY_ATTEMPTS")
              this.$store.commit(
                "alert/showError",
                this.$t("auth.login.errors.TOO_MANY_ATTEMPTS")
              );
            else
              this.$store.commit(
                "alert/showError",
                this.$t("auth.login.errors.LOGIN_NOT_VALID")
              );
          } else
            this.$store.commit("alert/showError", this.$t("common.errors.500"));
        })
        .finally(() => {
          this.isLoading = false;
          this.form.password = "";
        });
    },

    executeRecaptcha() {
      this.$refs.recaptcha.execute();
    },
  },
};
</script>

<style lang="scss"></style>
